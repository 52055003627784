export const DEFAULT_ALERT = {};
export const DEFAULT_BATCH = {};
export const DEFAULT_AUTO_STAFFING = {};

export const UPDATE_VERSION_ALERT = {
  dialog: {
    title: 'Update Required',
    text: 'To continue working, you must update to the latest version of Boost Retail. Click Install Now to continue.',
  },
  button: {
    onClick: () => {
      window.location.reload();
    },
    text: 'Install Now',
  },
};

export const AUTO_STAFFING_DIALOG = {
  dialog: {
    title: 'AI Assisted Staffing',
    text: 'For assignments to be eligible for AI assisted staffing, specific requirements must be met. Only assignments that meet these requirements will be shown.',
    listOfText: [
      'Unstaffed or On Hold',
      'Between now and 60 days from now',
      'Work Type: Demonstration, Demo - Adult Beverage',
      'Location has at least 1 Teammate that is active with that home store',
    ],
    revisedDate: null,
  },
};
